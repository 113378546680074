<!-- src/components/PanelPage.vue -->
<template>
    <div class="panel">
      <h1>Welcome to the Panel</h1>
    </div>
  </template>
  
  <script>
  export default {
    name: 'PanelPage'
  }
  </script>
  
  <style scoped>
  .panel {
    text-align: center;
  }
  </style>
  