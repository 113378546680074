<!-- src/components/LoginPage.vue -->
<template>
  <div class="login">
    <h1>Login with Google</h1>
    <GoogleLogin
      :client-id="clientId"
      @success="handleSuccess"
      @error="handleError"
    />
  </div>
</template>

<script>
import { GoogleLogin } from 'vue3-google-login';
import axios from 'axios'; // افزودن این خط

export default {
  components: {
    GoogleLogin
  },
  data() {
    return {
      clientId: '905669663086-1itd3lquba2rdnme6ae449en4ocbkeb9.apps.googleusercontent.com'
    };
  },
  methods: {
    handleSuccess(response) {
      const idToken = response.credential; // توکن دریافتی از گوگل

      // ارسال توکن به سرور برای تأیید
      axios.post('http://localhost:3000/auth/google', { idToken })
        .then(response => {
          console.log('Server Response:', response.data);
          // ذخیره توکن و مسیر به پنل اصلی
          localStorage.setItem('authToken', idToken);
          this.$router.push('/panel');
        })
        .catch(error => {
          console.error('Server Error:', error);
        });
    },
    handleError(error) {
      console.error('Login Error:', error);
    }
  }
};
</script>


<style scoped>
.login {
  text-align: center;
}
</style>
